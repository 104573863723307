import React, { useState, useEffect } from 'react';
import showdown from 'showdown'; // Import Showdown
import '../styles/MarkdownPage.css';

export default function MarkdownPage({ filename }) {
  const [markdown, setMarkdown] = useState('');
  const [html, setHtml] = useState(''); // Store the HTML result

  useEffect(() => {
    import(`../res/${filename}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => {
            setMarkdown(res);

            // Convert Markdown to HTML using Showdown
            const converter = new showdown.Converter();
            const htmlResult = converter.makeHtml(res);

            setHtml(htmlResult); // Set the HTML result in the state
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [filename]);

  return (
    <div className="markdown-container" dangerouslySetInnerHTML={{ __html: html }} /> // Render the HTML from the state into the DOM
  );
}
