import MarkdownContainer from './components/MarkdownContainer';

function App() {
  return (
    <>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/space-background-00.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }} />
      <MarkdownContainer />
    </>
  );
}

export default App;
