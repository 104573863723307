import { Route, Routes, BrowserRouter } from 'react-router-dom';
import MarkdownPage from './MarkdownPage';
import { Container } from 'react-bootstrap';

export default function MarkdownContainer() {

  // write function that maps a list of markdown file names to routes
  const markdownFiles = ['about', 'note', 'note/learning', 'note/math-resources', 'note/useful-sites-for-research'];

  return (
    <Container style={{
      maxWidth: '650px',
      position: 'relative',
    }}>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<MarkdownPage filename='home.md' />} />
          {markdownFiles.map((filename, index) => {
            return <Route key={index} path={`/${filename}`} element={<MarkdownPage filename={`${filename}.md`} />} />
          })}
        </Routes>
      </BrowserRouter>
    </Container>
  );
}
